import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAppDispatch } from "../../hooks/store";
import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

// import { setCredentials } from "../../reducers/authSlice";
// import { useAppDispatch } from "../../hooks/store";
// import { usePostLoginMutation } from "../../services/auth";

import "./Login.scss";
import { usePostLogInMutation } from "../../services/auth";
import { setToStorage, STORAGE_KEYS, removeFromStorage, getFromStorage } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { showToast, showError } from "../../constants/toast";
import { firebaseCloudMessaging } from "../../utils/firebase";
import Loader from "../../helpers/Loader";

// import {
//   Loader,
//   STORAGE_KEYS,
//   getFromStorage,
//   removeFromStorage,
//   setToStorage,
//   showError,
// } from "../../constants";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const[isLoading,setIsLoading]=useState(false);
  const[deviceToken,setDevicetoken]=useState<any>("");
  useEffect(() => {
    async function setToken() {
      setIsLoading(true);
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          setDevicetoken(fcmToken);
          console.log("FCM TOKEN : ",fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    setToken();
  },[]);
  const [showPassword, setShowPassword] = useState(false);
  const[loginMutation]=usePostLogInMutation();
  // const [loginMutation, { isLoading }] = usePostLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
    
      let body = {
        key: formik.values.email,
        password: formik.values.password,
        deviceType:"WEB",
        deviceToken:deviceToken
        // appKey: new Date().toISOString(),
      };
      console.log(body,"login_body");

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

        const response = await loginMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Logged In Successfully."||response?.message);
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {/* <h1 style={{color: 'white'}}>LIFEFIT</h1> */}
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Log in to LIFEFIT Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_auth">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_auth">Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Password"
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
            <Button
    className="btn btn_primary"
    color="primary"
    fullWidth
    size="large"
    type="submit"  
    variant="contained"
  >
    Sign In
  </Button>


            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
