import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import Parser from 'html-react-parser';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetDietByIdQuery, useLazyGetRecipeByDietQuery } from "../../services/diet";
import { showError, showToast } from "../../constants/toast";
import { handleDelete } from "../../utils/commonFunctions";
import { CameraAlt, Cancel, Visibility } from "@mui/icons-material";
import { type } from "@testing-library/user-event/dist/type";
import { error } from "console";
import EditText from "../../components/EditText";
import Loader from "../../helpers/Loader";
import { isNumber } from "../../utils/validations";
const RecipesDetails = () => {
  const[getDietById]=useLazyGetDietByIdQuery();
  const [getRecipeByDiet]=useLazyGetRecipeByDietQuery();
  const[currentDietName,setCurrentDietName]=useState("");
  const[currentDietImage,setCurrentDietImage]=useState("");
  const[currentDietStatus,setCurrentDietStatus]=useState("");
  const[selectedIndex,setSelectedIndex]=useState(0);
  const[recipes,setRecipes]=useState<any>([]);

  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const {id}=useParams();
  const getDietByIdData=async()=>{
    try{
      const response=await getDietById({id:id}).unwrap();
      if(response.statusCode==200)
      {
        setCurrentDietName(response.data.name);
        setCurrentDietImage(response.data.image);
        setCurrentDietStatus(response.data.isBlocked);
        console.log("RRRRR",response.data)
      }

    }
    catch(error:any)
    {
      showError(error?.data?.message);
    }
   
  }
  const handleCloseModal = () => {
    setOpenModal(false);
};
  const getRecipeByDietData = async(id:any)=>{
    try{
      const response=await getRecipeByDiet({id:id}).unwrap();
      if(response.statusCode==200)
      {   
          setRecipes(response?.data?.recipe)

          console.log(response);
      }
    }
    catch(error:any)
    {
      showError(error?.data?.message);
    }
  }
  useEffect(()=>{
    getDietByIdData();
    getRecipeByDietData(id);
  },[]);

  return (
    
         <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">View Diet Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-recipes");
              }}
            >
              Back
            </Button>
          </div>
       
          <Card className="cards">
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2} className="view_box">
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <figure className="profile_img">
                    <img src={currentDietImage?currentDietImage:"/static/images/recipe-placeholder.png"} alt="" />
                  </figure>
                </Grid>
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Diet Name</Typography>
                        <Typography component="p">{currentDietName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <Box>
                        <Typography component="h5">Status </Typography>
                        <Typography component="p">{currentDietStatus?"Inactive":"Active"}</Typography>
                      </Box>
                    </Grid>  
  
  
  
                    
                     
                   
                   
                  </Grid>
                  <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Recipe</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">View</TableCell>
                        </TableRow>
                      </TableHead>
                      {recipes?.length ? (
                        recipes?.map((item:any, i:any) => (
                          <TableBody>
                            <TableRow>
                              <TableCell className="mn_hdng">{i+1}</TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>
                                {item.isBlocked?"Inactive":"Active"}
                              </TableCell>
                              <TableCell>
                                <IconButton onClick={()=>{
                                  setSelectedIndex(i);
                                  setOpenModal(true)
                                  }}>
                                  <Visibility />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow

                          // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Recipe Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>


                  </Grid>
                  
                </Grid>
                <Dialog open={openModal} onClose={handleCloseModal} className="revert_dialog">

                <DialogContent>

                    <FormControl fullWidth>
                    <Typography className="custom_label">Image</Typography>
                    
                   <Grid container spacing={3}>
                     
                      <Grid item lg={2} md={2} sm={6} xs={12}>
                        <Box className="profile_img" style={{width:"300px",display:"flex",flexWrap:"wrap"}}>
                    {recipes[selectedIndex]?.image.map((row:any)=>{
                        return(
                      <img width={"100px"} height={"100px"} style={{borderRadius:"50%",padding:"5px"}} src={row} alt="" />
                    )
                    
                  })}
                  </Box>
                  </Grid>
                   </Grid>
                   <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12} sx={{paddingTop:"15px"}}>
                    <Box>
                      <Typography component="h5">Recipe Name</Typography>
                      <Typography component="p">{recipes[selectedIndex]?.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Preparation Time </Typography>
                      <Typography component="p">{recipes[selectedIndex]?.prepTime} Minutes</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Video available </Typography>
                      <Typography component="p">{recipes[selectedIndex]?.videolink?"Yes":"No"}</Typography>
                    </Box>
                  </Grid><Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Directions</Typography>
                      <Typography component="p">{recipes[selectedIndex]?.directions==""?"No directions given": (recipes[selectedIndex]?.notes && Parser(recipes[selectedIndex]?.directions))}</Typography>
                    </Box>
                  </Grid><Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Ingredients </Typography>
                      <Typography component="p">{recipes[selectedIndex]?.ingredients.trim()==""?"NO ingredients given": (recipes[selectedIndex]?.notes && Parser(recipes[selectedIndex]?.ingredients))}</Typography>
                    </Box>
                  </Grid><Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Notes </Typography>
                      <Typography component="p">{recipes[selectedIndex]?.notes.trim()==""?"NO notes given": (recipes[selectedIndex]?.notes && Parser(recipes[selectedIndex]?.notes))}</Typography>
                    </Box>
                  </Grid>
                  </Grid>
                        <Grid item xs={12}>
                            <TableContainer className="table_container">
                                <Box className="heading"></Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="mn_hdng">S.No.</TableCell>
                                            <TableCell className="mn_hdng">Nutrition</TableCell>
                                            <TableCell className="mn_hdng">Quantity</TableCell>*
                                        </TableRow>
                                    </TableHead>
                                    {(recipes[selectedIndex]?.nutritionArray?.length ? (
                                        recipes[selectedIndex]?.nutritionArray?.map((row: any, i: number) => (
                                            <TableBody key={i}>
                                                <TableRow>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>{row.nutrition}</TableCell>
                                                    <TableCell>{row.calorie}</TableCell>

                                                </TableRow>


                                            </TableBody>
                                        ))
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4} style={{ textAlign: "center" }}>
                                                    No Nutrition Added
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>


                            </TableContainer>
                        </Grid>
                    </FormControl>
                    
                </DialogContent>
            </Dialog>
              </Grid>
            </CardContent>
          </Card>
        </div>

  );
};

export default RecipesDetails;
