import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuth from "../../hooks/useAuth";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetUsersQuery, useEditUserByIdMutation, useDeleteUserByIdMutation, useLazyGetUserCSVQuery } from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useEditNotificationByIdMutation, useDeleteNotificationByIdMutation, useLazyGetNotificationQuery } from "../../services/manage-notification";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import Loader from "../../helpers/Loader";
import { Pagination } from "../../components";
import { Add } from "@mui/icons-material";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<any>(0);
  const [page, setPage] = useState(1);
  const [notification, setNotification] = useState<any>([]);
//   [{
//     _id: "23232",
//     fullName: "John Doe", // Optional
//     phone: "+1 123-456-7890",
//     image: "https://example.com/profile_image.jpg",
//     isBlocked: false,
//     countryCode: "US",
//     email: "john.doe@example.com",
//     userName: "johndoe", // Optional
//     address: "123 Main St, City, State, Zip"
// }]
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const convertDate=(datetimeStr:string)=>{
    const datetimeObj = new Date(datetimeStr);
    const dateStr = datetimeObj.toISOString().split('T')[0];
    
    return dateStr; // Output: 2024-04-24
    
        }
  const [getNotification] = useLazyGetNotificationQuery();
  const [updateUserStatus]:any = useEditNotificationByIdMutation();
  const [deleteById] = useDeleteNotificationByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getNotificationList = async () => {
    setIsLoading(true);
    try {
      const response = await getNotification({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotification(response?.data?.data || []);
        // console.log(response?.data?.notification);
        setTotalCount(response?.data?.count);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        isBlocked:!notification[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateUserStatus({ id: `${notification[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setNotification((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getNotificationList();
  }, [debouncedSearchTerm, page]);
  console.log(notification);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
 
      <div className="main_layout">
        <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h3 className="mn_hdng">Manage Notifications</h3>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {(user?.role==1)||(user?.permissions?.[7]?.isEdit)?<Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                {<Add/>}Add Notification
              </Button>:null}
              {/* <Button
                className="btn btn_primary"
                onClick={handleBroadcastNotification}
              >
                Broadcast Notification
              </Button> */}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  {/* <TableCell align="center">
                    Orders Updates
                  </TableCell>
                  <TableCell align="center">Status</TableCell>  */}
                  {(user?.role==1)||(user?.permissions?.[7]?.isDelete)?<TableCell align="center">Delete</TableCell>:null}
                </TableRow>
              </TableHead>
              <TableBody>
                {notification.length!=0 ? notification.map((row:any, index:number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{(page-1)*10+index+1}</TableCell>
                    <TableCell>{convertDate(row.createdAt)}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    {/* <TableCell align="center">
                       confirmed
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={row.status}
                            disabled={!(user?.role==1)||(user?.role==2) && (!user?.permissions?.[7]?.isEdit)}
                            onChange={() =>
                              {}
                            }
                          />
                        }
                        label={row.status ? "Confirmed" : "Not Confirmed"}  
                      />
                    </TableCell> */}
                    {(user?.role==1)||(user?.permissions?.[7]?.isDelete)?<TableCell align="center">
                      <IconButton
                      onClick={()=>{
                        setSelectedId(row._id);
                        setOpen(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>:null}
                  </TableRow>
                )):<TableRow><TableCell colSpan={5} sx={{textAlign:"center"}}>No Notification Found</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={notification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
        setOpen={setOpen}
        open={open}
        name={"notification"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getNotificationList)}
      />
      </div>

  );
};

export default ManageNotifications;
