import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import EditText from "../../components/EditText";

const AddBodyScan = () => {
  const navigate = useNavigate();

  const [category, setCategory] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
     <div>
        {/* <div className="main_layout">
          <div className="dashboard">
            <h3>Add Exercise</h3>
            <Button
                className="btn btn_primary"
              onClick={() => {
                navigate("/manage-exercises");
              }}
            >
              Back
            </Button>
          </div> */}
           <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Body-Scan</h1>
            <Button
              className="btn btn_primary"
              onClick={() =>               navigate("/manage-bodyScan")}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {video && (
                      <div className="upload_video_preview">
                        <video controls>
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon onClick={handleMediaCancel} />
                      </div>
                    )}
                    {/* {!video && (
                      <Box className="upload_video">
                        <label htmlFor="video-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="video-button-file"
                            type="file"
                            inputProps={{
                              accept: "video/mp4",
                            }}
                            onChange={handleVideoChange}
                          />
                          <Button
                            component="span"
                            className="upload_video_btn"
                          >
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )} */}
                      <Grid item xs={4} sx={{ p: 0 }}>
                     
  
                      <div className="upload_document w_50">
                        {/* {frontImg ? (
                          <div className="inner">
                            <div className="cross_icn">
                              <CloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFrontImg("");
                                }}
                              />
                            </div>
  
                            <figure>
                              <LazyLoadImage
                                width={"100%"}
                                height={"100%"}
                                alt="photo"
                                src={frontImg ? frontImg : ""}
                                effect="blur"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                          </div>
                        ) : ( */}
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              // onChange={(
                              //   e: React.ChangeEvent<HTMLInputElement>
                              // ) => handleImageUpload(e, "front")}
                            />
                          </div>
  
                          {/* {error && !frontImg ? (
                              <h6 className="err_msg">This field is required</h6>
                            ) : null} */}
                        </label>
                        {/* )} */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                    Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                    Feature Info
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Feature Info"
                    />
                  </Grid> */}
                  <Grid item xs={12}>
    <Typography className="custom_label">Feature Info</Typography>
    <div style={{ width: '66%' }}> {/* Adjust the width as needed */}
      <EditText
        content={description}
        setContent={setDescription}
      />
    </div>
  {/* </Grid>
                  <Grid item xs={12}> */}
  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
  
    <div style={{ width: '66%' }}> {/* Adjust the width as needed */}
      <EditText
        content={description}
        setContent={setDescription}
      />
    </div>
  </Grid>
                      {/* Add the description text field below the video upload
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      placeholder="Description"
                    />
                  </Grid> */}
                 
                 
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
     </div>
  );
};

export default AddBodyScan;
