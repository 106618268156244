import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { STORAGE_KEYS, errorToast, getFromStorage, removeFromStorage, setToStorage, successToast } from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { usePutUpdateProfileMutation } from "../../services/profile";
import { UploadResponse } from "../../types/User";
import { useNavigate } from "react-router-dom";
import { CameraAlt,  Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/store";
import { getToken, setCredentials } from "../../reducers/authSlice";
import { showError } from "../../constants/toast";
import Loader from "../../helpers/Loader";
const Profile = () => {
const user:any = useAuth();
console.log(user);
  const token=useSelector(getToken);
  const dispatch=useAppDispatch();
  const navigate = useNavigate();
  const [updateprofile] :any= usePutUpdateProfileMutation();
  const [countryCode, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState<any>(null);
  const [imageId, setImageId] = useState(null);
  const[phoneDisplay,setPhoneDisplay]=useState("");
  const[isLoading,setIsLoading]=useState(false);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {

        formik.setFieldValue("profile_picture", "");
        setProfilePicture("");
        setImageId(null);
        return;
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
         setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      setIsLoading(true);
      const res = await UploadMedia(file);
//      console.log(res,'lklk');
      
      if (res?.statusCode === 200) {
        formik.setFieldValue("profile_picture", res?.data);
        setProfilePicture(res?.data);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setIsLoading(false);
  };
//console.log(user);
//  console.log("ussser", user);
  const formik:any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      fullName: user?.fullName || "",
      phone: user?.phone || "",
      profile_picture: user?.image || "",
      address:user?.address||"",
      countryCode: countryCode||"+91",

    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      fullName: Yup.string()
        .required("First Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 60 characters are allowed"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 digits")
        .max(16, "Phone number must be at least 16 digits"),
      // address: Yup.string()
      //   .required("Address is required")
      //   .max(100, "Maximum 100 characters are alloweds")




    }),
    onSubmit: async (values, { setSubmitting }) => {
      // alert("Im here")
      // if (!formik.isValid) return;
      try {
       setSubmitting(true);

        let body: {
          // email: string;
          fullName: string;
          countryCode: string;
          address:string;
          phone: string;
          image?: string;
        } = {
          // email: values.email,
          fullName: values?.fullName||user?.fullName,
          countryCode: values?.countryCode||user?.countryCode,
          address: values?.address||user?.address,
          phone: values?.phone||user?.phone,
          image: values?.profile_picture||user?.image
        };
        
        console.log('body :', body);
        // console.log(body, "body")
        // if (values.profile_picture) {
        //   body.image = values.profile_picture;
        // }
        
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateprofile(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          dispatch(
            setCredentials({
              user:{...user,fullName:values.fullName,image:profile_picture,phone:values?.phone,countryCode:values?.countryCode},
              token:getFromStorage(STORAGE_KEYS.token)
            })
          )
          successToast("Profile Updated Successfully.");
          const token = getFromStorage(STORAGE_KEYS.token);
          navigate("/dashboard");
        }
      } catch (error: any) {
        errorToast("error");
      } finally {
        setSubmitting(false);
      }
    },


  });


  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phone",
      phone.replace(country.dialCode, "")
    );
    setPhoneCode(country.dialCode);
    setPhoneDisplay(phone);
  };
  useEffect(() => {
    setPhoneCode(user?.countryCode || "");
    setProfilePicture(user?.image || "");
    setPhoneDisplay(user?.countryCode+user?.phone||"");
  }, [user]);

  return (
  
      <div className="main_layout">
        <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h1>Edit Profile</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {formik.values.profile_picture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.profile_picture}
                          alt="photo"
                        />
                        <Cancel
                          onClick={(e) => {
                            e.preventDefault();
                            setProfilePicture("");
                            formik.setFieldValue("profile_picture", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAlt />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Full name</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    placeholder="Full Name"
                    value={formik.values.fullName}
                    onChange={(e)=>{

                      if(e.target.value.length<=60)
                      formik.handleChange(e);
                    else
                    showError("Name can't exceed 60 characters")
                    }
                  
                  }
                    onBlur={formik.handleBlur}
                    error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                    helperText={(formik.touched.fullName && formik.errors.fullName && typeof formik.errors.fullName === 'string') && formik.errors.fullName}

                  />
                 
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    hiddenLabel
                    type="email"
                    name="email"
                    disabled
                    variant="outlined"
                    fullWidth 
                    onMouseOver={(e:any)=>{
                      e.target.style.cursor = 'not-allowed';
                    }}
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={(formik.touched.email && formik.errors.email && typeof formik.errors.email === 'string') && formik.errors.email}
                 
                  />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Address</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="Address"
                    value={formik.values.address}
                    onChange={(e)=>{
                      if(e.target.value.length<=100)
                      formik.handleChange(e);
                    else
                    showError("Address cant exceed 100 characters")

                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={(formik.touched.address && formik.errors.address && typeof formik.errors.address === 'string') && formik.errors.address}
                  /> */}
                  {/* {formik.touched.address && formik.errors.address && (
                    <Typography variant="caption" color="error">
                      {formik.errors.address}
                    </Typography>
                  )} */}
                {/* </Grid> */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Phone number</Typography>
                  <PhoneInput
  country={"us"}
  placeholder="0 (000) 000-000"
  enableSearch={true}
  inputStyle={{ width: "100%" }}
  value={phoneDisplay}
  // onChange={(value) => formik.setFieldValue("phone", value)}
  onChange={(phone, country) =>
    handleChangePhone(phone, country)
  }
  onBlur={formik.handleBlur}
  // isValid={!(formik.touched.phone && formik.errors.phone)}
/>
{formik.touched.phone && formik.errors.phone && (
  <Typography variant="caption" color="error">
    Phone number is required
  </Typography>
)}

                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>

  );
};

export default Profile;
