import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { SIDEBAR_WIDTH } from "../constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./Layout.scss";
import { STORAGE_KEYS, getFromStorage, removeFromStorage } from "../helpers";
import { setCredentials } from "../reducers/authSlice";
import { useAppDispatch } from "../hooks/store";
import { useLazyGetUserQuery, usePostLogoutMutation } from "../services/auth";
import useAuth from "../hooks/useAuth";
import { LogoutModal } from "../components";
import secureLocalStorage from "react-secure-storage";
import { showError } from "../constants/toast";
import { useLazyGetAdminNotificationQuery } from "../services/notificationRecieve";
import Loader from "../helpers/Loader";
console.log('XYZ');
interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getUser] = useLazyGetUserQuery();
  const[isLoading,setIsLoading]=useState(false);
  const [logoutMethod] = usePostLogoutMutation();
  const[adminNotification,setAdminNotification]=useState<any>([]);
  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const user = useAuth();

if(user){
  secureLocalStorage.setItem("permissions",JSON.stringify(user?.permissions));
  secureLocalStorage.setItem("role",user?.role);
  console.log("Ssssssss",user?.role);

}  console.log("user: ", user);
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      // getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
 const getElaspedTime=(dateTimeStr:string) =>{
  const now:any = new Date();
  const dateTime:any = new Date(dateTimeStr);
  const elapsedMilliseconds = now - dateTime;

  const seconds = Math.floor(elapsedMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (days < 30) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (months < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
}
  const[getAdminNotification]=useLazyGetAdminNotificationQuery();
  const getAdminNotificationList = async () => {
     setIsLoading(true);
     try {
       const response = await getAdminNotification({
         page: 1,
         // limit: 10,
         query: "",
       }).unwrap();
       if (response?.statusCode === 200) {
         setAdminNotification(response?.data?.data || []);
         console.log("ADMINNNN",response?.data);
       } else {
         setAdminNotification([]);
       }
     } catch (error: any) {
       showError(error?.data?.message || "");
     }
     setIsLoading(false);
   };
   useEffect(()=>{
    getAdminNotificationList();
   },[])
  const handleLogout = async () => {
    try {

      const res = await logoutMethod({}).unwrap();
      if(res.statusCode==200){
      navigate("/");
      secureLocalStorage.removeItem('permissions');
      secureLocalStorage.removeItem('role');
      removeFromStorage(STORAGE_KEYS.token);
      removeFromStorage(STORAGE_KEYS.tempToken);
      dispatch(
        setCredentials({
          user: null,
          token: null,
        })
      );}
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <Box className="container top_bar">
    <Loader isLoad={isLoading} />
    <AppBar
      position="fixed"
      className="topbar"
      sx={{
        width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        ml: { lg: `${SIDEBAR_WIDTH}px` },
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          color="inherit"
          aria-label="open sidebar"
          edge="start"
          onClick={handleSidebarToggle}
          style={{ backgroundColor: "#72cedd" }}
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ mr: 2 }}>
            <IconButton
              className="notification_btn"
              aria-controls={open1 ? "basic-menu1" : undefined}
              aria-haspopup="true"
              aria-expanded={open1 ? "true" : undefined}
              onClick={handleClick1}
            >
              <NotificationsActiveIcon />
            </IconButton>
            <Menu
              className="notiDropdown_menu"
              id="basic-menu1"
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleClose1}
              MenuListProps={{
                "aria-labelledby": "basic-button1",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 20,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Box className="noti_head">
                <Typography component="h2" sx={{paddingInline:"15px"}}>Notifications</Typography>
               {(user?.role==1)||(user?.permissions?.[7].isView)? <Button
                  className="btn btn_primary sm"
                  onClick={() => navigate("/recieved-notifications")}
                >
                  See All
                </Button>:null}
              </Box>
              <ul className="noti_list">
                {adminNotification.length!=0? adminNotification.slice(0, 3).map((row:any,index:number)=>{
                  return <li>
                  <figure>
                    <img
                      src={row?.senderId?.image?adminNotification.senderId?.image:"/static/images/user_placeholder.png"}
                      alt=""
                    />
                  </figure>
                  <div className="noti_info">
                    <Typography component="h3">
                      {row?.senderId?.fullName}
                      <Typography component="span">{getElaspedTime(row.createdAt)}</Typography>
                    </Typography>
                    <Typography component="p">
                      {row?.message}
                    </Typography>
                  </div>
                </li>
                }): 
                  <li style={{textAlign:"center"}}>
                  No Notifications
                  </li>
                 
                }
                
              </ul>
            </Menu>
          </Box>

          <Box>
            <IconButton
              className="topbar_user_btn"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img
                src={
                  user?.image
                    ? user?.image
                    : "/static/images/user_placeholder.png"
                }
                alt=""
              />
              <span>
                {(user?.fullName || "Admin")}
              </span>
              <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
              className="userDropdown_menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 10,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                className="userDropdown_menu_list"
                onClick={() => navigate("/profile")}
              >
                <IconButton className="drpdwn_icns">
                  <PersonIcon />
                </IconButton>
                My Profile
              </MenuItem>
              <MenuItem
                className="userDropdown_menu_list"
                onClick={() => navigate("/changepassword")}
              >
                <IconButton className="drpdwn_icns">
                  <LockResetIcon />
                </IconButton>
                Change Password
              </MenuItem>

              <MenuItem
                className="userDropdown_menu_list"
                onClick={() => setOpenLogout(true)}
              >
                <IconButton className="drpdwn_icns">
                  <LogoutIcon />
                </IconButton>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
    <LogoutModal
      openLogout={openLogout}
      setOpenLogout={setOpenLogout}
      handleLogout={handleLogout}
    />
  </Box>
);
}


export default Topbar;
