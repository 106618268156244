import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

const AddWorkoutRoutine = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [format, setFormat] = useState("");
  const [category, setCategory] = useState("");
  const [isPersonal, setIsPersonal] = useState(false);
 
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
 
  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event: any) => {
    setContent(event.target.value);
  };

  const handleFormatChange = (event: any) => {
    setFormat(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Handle form submission
    // For example, send data to server, navigate to another page, etc.
    navigate("/mworkout-routines-management/");
  };
  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };
  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };
  return (

<div>
  
          {/* <div className="dashboard">
            <h3>Add Workout Routine</h3>
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/workout-routines-management/")}
            >
              Back
            </Button>
          </div> */}
           <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Workout Routine</h1>
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/workout-routines-management/")}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography className="custom_label">Exercise Name</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={title}
                      placeholder="Exercise Name"
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Format
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Basic">Test1</MenuItem>
                          <MenuItem value="Standard">Test2</MenuItem>
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Category
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Basic">Test1</MenuItem>
                          <MenuItem value="Standard">Test2</MenuItem>
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Accessibility
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Basic">Test1</MenuItem>
                          <MenuItem value="Standard">Test2</MenuItem>
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <Typography className="custom_label">
                        Subscription
                        </Typography>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleCategoryChange}
                          displayEmpty
                          // sx={{ minWidth: "250px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Basic">Needed</MenuItem>
                          <MenuItem value="Standard">Not Needed</MenuItem>
                         
                           
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography className="custom_label">
                        Content
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={4}
                        placeholder="Content"
                        style={{ width: '32.5%' }} // Adjust the width as needed
                      />
  
                    </Grid>
                    <Grid item xs={12}>
                    <Typography className="custom_label">Upload Workout Routines</Typography>
                    {video && (
                      <div className="upload_video_preview">
                        <video controls>
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon onClick={handleMediaCancel} />
                      </div>
                    )}
                    {/* {!video && (
                      <Box className="upload_video">
                        <label htmlFor="video-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="video-button-file"
                            type="file"
                            inputProps={{
                              accept: "video/mp4",
                            }}
                            onChange={handleVideoChange}
                          />
                          <Button
                            component="span"
                            className="upload_video_btn"
                          >
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )} */}
                      <Grid item xs={4} sx={{ p: 0 }}>
                     
  
                      <div className="upload_document w_50">
                        {/* {frontImg ? (
                          <div className="inner">
                            <div className="cross_icn">
                              <CloseIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFrontImg("");
                                }}
                              />
                            </div>
  
                            <figure>
                              <LazyLoadImage
                                width={"100%"}
                                height={"100%"}
                                alt="photo"
                                src={frontImg ? frontImg : ""}
                                effect="blur"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                          </div>
                        ) : ( */}
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              // onChange={(
                              //   e: React.ChangeEvent<HTMLInputElement>
                              // ) => handleImageUpload(e, "front")}
                            />
                          </div>
  
                          {/* {error && !frontImg ? (
                              <h6 className="err_msg">This field is required</h6>
                            ) : null} */}
                        </label>
                        {/* )} */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button  className="btn btn_primary" type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
  
</div>
  );
};

export default AddWorkoutRoutine;
