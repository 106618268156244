import React from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";

const ManageFitnessTracker = () => {
  const navigate = useNavigate();

  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const rows = [
   
    createData(
      "John Doe",
      "JohnDoe",
      "+971056734321",
      "johndoe@gmail.com",
      "Yearly",
      "$29.99/yr",
      "10",
      "$299.90"
    ),
    
  ];

  return (
    
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Fitness-Tracker</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={" "}
              // setDebouncedSearchTerm={setDebouncedSearchTerm}
              setDebouncedSearchTerm={()=>{}}
              value={" "}
              onCross={() => {}}
              onChange={()=>{}}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-fitness-tracker/add")}
              >
                Add Fitness-Tracker
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Title</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>
                        <figure className="user_img">
                          <img src="/static/images/fitness.png" alt="" />
                        </figure>
                      </TableCell>
                    <TableCell>Amazfit Band Fitness Tracker</TableCell>
                    {/* <TableCell>
                      <Typography variant="h6" fontSize={14}>
                      Always-on AMOLED Display, Alexa Built-in
                      </Typography>
                    </TableCell> */}
                     
                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-fitness-tracker/details")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate("/manage-fitness-tracker/add", {
                              state: { type: "edit" },
                            })
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
  );
};

export default ManageFitnessTracker;
