import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import EditText from "../../components/EditText";
import { useAddFastingMutation, useEditFastingByIdMutation, useLazyGetFastingsByIdQuery } from "../../services/fasting";
import { error } from "console";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/uploadMedia";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../helpers/Loader";
import { EditText2 } from "../../components/EditText2";

const AddFasting = () => {
  const navigate = useNavigate();
  const{id}=useParams();
  const[title,setTitle]=useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const[Suppliments,setSuppliments]=useState<string>("");
  const[error,setError]=useState<boolean>(false);
  const[displayEditText,setDisplayEditText]=useState<boolean>(true);
  const[isLoading,setIsLoading]=useState(false);
  const[addFasting]=useAddFastingMutation();
  const[getFastingById]=useLazyGetFastingsByIdQuery();
  const[editFastingById]=useEditFastingByIdMutation();


  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handleSupplimentsChange = (event: SelectChangeEvent) => {
    setSuppliments(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };
  const editData=async()=>{
    try{
      const body={
        image:image,
        title:title,
        description:description
      }
      const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
      const response=await editFastingById({id:id,body:encryptedBody}).unwrap();
      if(response.statusCode==200)
        {
          showToast("Fasting updated succesfully")
        }
    }
    catch(error:any)
    {
      showError(error?.data?.message);
    }
  }
  const getData=async(id:string)=>{
    try{
    setDisplayEditText(false);
    const response=await getFastingById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    setImage(response?.data?.image);
    setTitle(response?.data?.title);
    setDescription(response?.data?.description);
    }
    
    }
    catch(error:any)
    {
    showError(error?.data?.message || "");
    }
    finally{
      setDisplayEditText(true);
    }
  
  }
useEffect(()=>{
  if(id)
  getData(id);
},[])
  const addFastingData=async()=>{
  try{
    const body={
      image:image,
      description:description,
      title:title
    }
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody
    const response=await addFasting(encryptedBody).unwrap();
    if(response.statusCode==200)
    {
      showToast("Fasting added successfully")
    }
  }
  catch(error:any)
  {
    showError(error.data.message);
  }
  }

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true)
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if(!file) 
      {
        showError("Invalid file");
        setIsLoading(false)  
        return
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
        setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
    setIsLoading(false);
  };
  return (
   
  <div>
        {/* <div className="main_layout">
          <div className="dashboard">
            <h3>Add Exercise</h3>
            <Button
                className="btn btn_primary"
              onClick={() => {
                navigate("/manage-exercises");
              }}
            >
              Back
            </Button>
          </div> */}
          <Loader isLoad={isLoading}/>
           <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit":"Add"} Fasting</h1>
            <Button
              className="btn btn_primary"
              onClick={() =>               navigate("/manage-fasting")}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {image ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={image} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  :  "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  
                 
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                    Category
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Category"
                    />
                  </Grid> */}
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                   Method
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Method"
                    />
                  </Grid> */}
                     <Grid item lg={5} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="Title"
                      value={title}
                      onChange={(e)=>{
                        if(e.target.value.length<=50)
                        setTitle(e.target.value)
                        if(e.target.value.length>50)
                        showError("Title cant exceed 50 characters")

                      }}
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    />
                    {error && title=="" ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {/* <Grid item xs={12}>
    <Typography className="custom_label">Description</Typography>
    <div style={{ width: '66%' }}> 
      { displayEditText && <EditText
        content={description}
        setContent={setDescription}
      />}
    </div>
  </Grid> */}
  <Grid item xs={12}>
    <Typography className="custom_label">Description</Typography>
    <div style={{ width: '66%' }}> {/* Adjust the width as needed */}
      {<EditText2
       content={description}
       setContent={setDescription}
      />}
    </div>
  </Grid>
                      {/* Add the description text field below the video upload
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      placeholder="Description"
                    />
                  </Grid> */}
                 
                 
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={()=>{
                      if(image=="")
                      {
                          showError("Image is required");
                          return;
                      }
                      if(title=="")
                      {
                          showError("Title is required")
                          return;
                      }
                      if(title.length>50)
                        {
                          showError("Title cant exceed 50 characters")
                          return;
                        }
                      if(id)
                      editData()
                      else
                      addFastingData();
                      navigate('/manage-fasting')
                    }}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
  
  </div>
  );
};

export default AddFasting;
