import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
 import TrainingPlan from "./trainingPlan/add";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
 import ManageSurveyQuestionnaries from "./manageSurveyQuestionnaries";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageExercise";
import AddCategories from "./manageExercise/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
 import WorkoutRoutinesDetails from "./manageWorkoutRoutines/details";
import SubCategories from "./manageExercise/details";
import ChangePassword from "./auth/changePassword";
 import { AdddSubRecipe } from "../features";
import ManageChallenges from "./ManageRecipes";
import AddChallenge from "./ManageRecipes/add";
import RecipesDetails from "./ManageRecipes/details";
import ManageProducts from "./manageSubScription";
import AddProducts from "./manageSubScription/add";
 
import ProductDetails from "./manageSubScription/details";
 import DetailsExercises from "./manageExercise/details"; 
 
import ManageExercises from "./manageExercise";
import ManageRecipes from "./ManageRecipes";
import AddRecipes from "./ManageRecipes/add";
import ManageSubscription from "./manageSubScription";
import SubscriptionDetails from "./manageSubScription/details";
import WorkoutVideosManagement from "./manageWorkoutVideos";
import Addsubscription from "./manageSubScription/add";
import AddWorkoutVideos from "./manageWorkoutVideos/add";
import WorkoutRoutineManagement from "./manageWorkoutRoutines";
import ManageSurveyQuestionnaires from "./manageSurveyQuestionnaries";
import AddWorkoutRoutine from "./manageWorkoutRoutines/add";
import WorkoutVideoDetails from "./manageWorkoutVideos/details";
import MnnageBodyScan from "./bodyscan";
import AddBodyScan from "./bodyscan/add";
import ManageBodyScanDetails from "./bodyscan/details";
import ManageFitnessTracker from "./fitnesstracker";
import ManageFitnessTrackerDetails from "./fitnesstracker/details";
import AddFitnessTracker from "./fitnesstracker/add";
import MnnageBioHacking from "./bioHacking";
import AddBioHacking from "./bioHacking/add";
import BioHackingDetails from "./bioHacking/details";
import BreakfastAddRecipeDetailed from "../features/recipe/BreakfastrecipeDetailed";
import AddFasting from "./manageFasting/add";
import ManageFasting from "./manageFasting";
import FastingDetails from "./manageFasting/details";
import ManageTrainingPlan from "./trainingPlan";
import AddTrainingPlan from "./trainingPlan/add";
import TrainingPlandetails from "./trainingPlan/details";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
 
  ManageUsers,
 
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
 
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
 
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
 
  SubCategories,
  ChangePassword,
  
  ManageChallenges,
  AddChallenge,
  RecipesDetails,
  ManageProducts,
  AddProducts,
  ProductDetails,
  WorkoutVideosManagement,
  AddWorkoutVideos,
  ManageExercises,
  ManageRecipes,
  AddRecipes,
  ManageSubscription,
  SubscriptionDetails,
  Addsubscription,
  ManageSurveyQuestionnaries,
  DetailsExercises,
  WorkoutRoutineManagement,
  AddWorkoutRoutine,
  WorkoutRoutinesDetails,
  WorkoutVideoDetails,
  AdddSubRecipe,
  MnnageBodyScan,
  AddBodyScan,
  ManageBodyScanDetails,
  ManageFitnessTracker,
  ManageFitnessTrackerDetails,
  AddFitnessTracker,
  MnnageBioHacking,
  AddBioHacking,
  BioHackingDetails,
  BreakfastAddRecipeDetailed,
  AddFasting,
  ManageFasting,
  FastingDetails,
  ManageTrainingPlan,
  AddTrainingPlan,
  TrainingPlandetails
};
