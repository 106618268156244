import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { showError, showToast } from "../../constants/toast";
import { useDeleteUserByIdMutation, useEditUserByIdMutation, useLazyChangeUserStatusQuery, useLazyGetUserCSVQuery, useLazyGetUsersQuery } from "../../services/user";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";
import { UserResponse } from "../../types/User";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import Loader from "../../helpers/Loader";

const ManageUsers = () => {
// user?.permissions?.[0]?.isDelete
  const user = useAuth();
  const navigate = useNavigate();
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<UserResponse[]>([]);
//   [{
//     _id: "23232",
//     fullName: "John Doe", // Optional
//     phone: "+1 123-456-7890",
//     image: "https://example.com/profile_image.jpg",
//     isBlocked: false,
//     countryCode: "US",
//     email: "john.doe@example.com",
//     userName: "johndoe", // Optional
//     address: "123 Main St, City, State, Zip"
// }]
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getUsers] = useLazyGetUsersQuery();
  const [updateUserStatus]:any = useEditUserByIdMutation();
  const [deleteById] = useDeleteUserByIdMutation();
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getUsersList = async () => {
    setIsLoading(true);
    try {
      const response = await getUsers({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setUsers(response?.data?.user || []);
        setTotalCount(response?.data?.count);
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({ search: searchTerm }).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        isBlocked:!users[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateUserStatus({ id: `${users[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setUsers(prevData=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getUsersList();
  }, [debouncedSearchTerm, page]);
  console.log(users);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <div className="main_loyout">
      <Loader isLoad={isLoading}/>

        <div className="dashboard">
          <h1 className="mn_hdng">Manage Users</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
          <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            
            <Box className="cards_header_right">
              <Button onClick={handleExportCsv} className="btn btn_primary">
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Full name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  {/* <TableCell>Address</TableCell> */}
                  
                <TableCell>Account Status</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {users?.length ? (
                users?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                        <figure className="user_img">
                          <img src={item?.image? item.image:"/static/images/user_placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                        <TableCell>{(item?.fullName)|| "-"}</TableCell>

                        <TableCell>
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                            item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell>{item?.email || "-"}</TableCell>
                        {/* <TableCell>{item?.address || "-"}</TableCell> */}

                        
                        <TableCell>
                        <Switch
                            {...label}
                            size="small"
                            checked={!item?.isBlocked}
                            disabled={!((user?.role==1)||(user?.role==2 && user?.permissions?.[0].isEdit))}
                            onChange={()=>handleStatusChange(i)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-users/details/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {/* {hidePermission?.isEdit ||
                              userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-users/usersform/${item?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : null}
                            {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(item?._id);
                                  }}
                                />
                              </IconButton>
                            ) : null} */}
                           {(user?.role==1)||(user?.permissions?.[0]?.isEdit)? <IconButton
                                onClick={() =>
                                  navigate(`/manage-users/usersform/${item?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>:null}
                            { (user?.role==1)||(user?.permissions?.[0]?.isDelete)?<IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(item?._id);
                                  }}
                                />
                              </IconButton>:null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    {"No User Found" || ""}
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={users}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={"user"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getUsersList)}
      />
    </>
  );
};

export default ManageUsers;
