//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/exercise";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllExerciseResponse = {
    map(arg0: (data: any) => any): any;
    data: any;
    // exercise: any[];
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    wpagination?:boolean;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getexercise:builder.query<
CommonResponseType & {data:GetAllExerciseResponse},
    GetTokenParams>({
        // query:({page,limit,query})=>{
        query:({page,wpagination,query})=>{
          let url = END_POINTS.exercise;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if(wpagination===true)
        {
          queryParams.push(`wpagination=${wpagination}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),

getexercisesById:builder.query<CommonResponseType & {data:any},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.exercise}/${id}`,
        method:"GET",
    })
}),

changeexerciseStatus: builder.query<
CommonResponseType & { data: any },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),

editexerciseById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string|undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.exercise}/${id}`,
        method: "PUT",
        body,
      }),
    }),

deleteexerciseById:builder.mutation<
CommonResponseType & {data:any},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.exercise}/${id}`,
        method:'DELETE'
    })
}),

addexercise:builder.mutation<
CommonResponseType & {data:any},
CommonBody
>({
  query:(body)=>({
    url:END_POINTS.exercise,
    method:'POST',
    body
  })
})

})
})
export const{
 useLazyGetexerciseQuery,
 useLazyGetexercisesByIdQuery,
 useEditexerciseByIdMutation,
 useAddexerciseMutation,
 useDeleteexerciseByIdMutation
}=userApi;
