import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import HTMLReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchBar from "../../components/SearchBar";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useLazyGetUsersQuery, useEditUserByIdMutation, useDeleteUserByIdMutation, useLazyGetUserCSVQuery } from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetworkoutVideosQuery, useEditworkoutVideoByIdMutation, useDeleteworkoutVideoByIdMutation } from "../../services/workoutVideos";
import { WorkoutVideoResponse } from "../../types/workoutVideos";
import { handleDelete } from "../../utils/commonFunctions";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/modals/WarnModal";
import Loader from "../../helpers/Loader";
import { Pagination } from "../../components";
import { Add } from "@mui/icons-material";

const WorkoutVideosManagement = () => {
  const navigate = useNavigate();
  const[isLoading,setIsLoading]=useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [videos, setVideos] = useState<any>([]);
//   [{
//     _id: "23232",
//     fullName: "John Doe", // Optional
//     phone: "+1 123-456-7890",
//     image: "https://example.com/profile_image.jpg",
//     isBlocked: false,
//     countryCode: "US",
//     email: "john.doe@example.com",
//     userName: "johndoe", // Optional
//     address: "123 Main St, City, State, Zip"
// }]
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const user=useAuth();
  const [getVideos] = useLazyGetworkoutVideosQuery();
  const [updateVideoStatus]:any = useEditworkoutVideoByIdMutation();
  const [deleteById] = useDeleteworkoutVideoByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getVideosList = async () => {
    setIsLoading(true);
    try {
      const response = await getVideos({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setVideos(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setVideos([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  const handleStatusChange=async(index:number)=>{
    try {
      const body = {
        isBlocked:!videos[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateVideoStatus({ id: `${videos[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "Status Updated Successfully");
      setVideos((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }
  useEffect(() => {
//   console.log("TESTING")
    getVideosList();
  }, [debouncedSearchTerm, page]);
  console.log(videos);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [categories, setCategories] = useState([
    {
      id: 1,
      name: "Strength Training",
      videos: [
        {
          id: 1,
          title: "Full Body Workout",
          duration: "30:00",
          description: "A full body strength training workout routine.",
        },
        {
          id: 2,
          title: "Leg Day",
          duration: "20:00",
          description: "Workout focused on strengthening leg muscles.",
        },
      ],
    },
    {
      id: 2,
      name: "Yoga",
      videos: [
        {
          id: 3,
          title: "Morning Yoga Flow",
          duration: "25:00",
          description: "Start your day with a refreshing yoga routine.",
        },
        {
          id: 4,
          title: "Power Yoga",
          duration: "40:00",
          description: "An intense yoga session to build strength and flexibility.",
        },
      ],
    },
  ]);

  // Function to add a new category
  const handleAddCategory = () => {
    const newCategory = {
      id: categories.length + 1,
      name: "New Category",
      videos: [],
    };
    setCategories([...categories, newCategory]);
  };

  // Function to remove a category
  const handleRemoveCategory = (categoryId:any) => {
    const updatedCategories = categories.filter((category) => category.id !== categoryId);
    setCategories(updatedCategories);
  };

  // Function to add a new video to a category
  const handleAddVideo = (categoryId:any) => {
    const newVideo = {
      id: categories[categoryId - 1].videos.length + 1,
      title: "New Video",
      duration: "00:00",
      description: "Description of the new video.",
    };
    const updatedCategories = [...categories];
    updatedCategories[categoryId - 1].videos.push(newVideo);
    setCategories(updatedCategories);
  };

  return (
    
      <div className="main_layout">
      <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h3 className="mn_hdng">Manage Workout Videos</h3>
          
        </div>
        <Card className="cards">
          
        <Box className="cards_header">
        <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {(user?.role==1)||(user?.permissions?.[2]?.isEdit)?<Button className="btn btn_primary"             onClick={() => {
              navigate("/workout-videos-management/add");
            }}>
                {<Add/>} Add Workout Video

              </Button>:null}
            </Box>
          </Box>
          
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>

                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Video</TableCell>
                  <TableCell align="center">Title</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Duration</TableCell>
                  <TableCell align="center">Paid Video</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  videos.length?(videos.map((video:WorkoutVideoResponse, index:number) => (
                    <TableRow key={video._id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <figure className="user_img">
                          <img src={video.image?video.image:"/static/images/workoutvideo-placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell align="center">{video.exerciseName}</TableCell>
                      <TableCell align="center" >{video?.workoutCategory?.name}</TableCell>
                      <TableCell align="center">{video.duration}</TableCell>
                      <TableCell align="center">{video?.paid?"Yes":"No"}</TableCell>

                      {/* <TableCell>{video.description && HTMLReactParser(video.description)}</TableCell> */}

                      <TableCell align="center">
                        <Box className="table_actions">
                          <IconButton onClick={() => navigate(`/workout-videos-management/details/${video._id}`)}>
                            <VisibilityIcon />
                          </IconButton>
                          {(user?.role==1)||(user?.permissions?.[2]?.isEdit)?<IconButton onClick={() => navigate(`/workout-videos-management/edit/${video._id}`)}>
                            <ModeEditIcon />
                          </IconButton>:null}
                          {(user?.role==1)||(user?.permissions?.[2]?.isDelete)?<IconButton onClick={() => {
                            setSelectedId(video._id);
                            setOpen(true);
                          }}>

                            <DeleteIcon />
                          </IconButton>:null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                )):<TableRow>
                <TableCell colSpan={7} sx={{textAlign:"center"}}>
                  No Videos Found</TableCell></TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={videos}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
        <WarnModal
        setOpen={setOpen}
        open={open}
        name={"video"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getVideosList)}
      />
      </div>
 
  );
};

export default WorkoutVideosManagement;
