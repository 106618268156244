//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { AdminNotificationResponse } from "../types/AdminNotification";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetAllAdminNotificationResponse = {
    data: any[];
    // AdminNotification: AdminNotificationResponse[];
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getAdminNotification:builder.query<
CommonResponseType & {data:GetAllAdminNotificationResponse},
    GetTokenParams>({
      
        // query:({page,limit,query})=>{
        query:({page,query})=>{
          let url = END_POINTS.adminNotification;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),


deleteAdminNotifications:builder.mutation<
CommonResponseType & {data:any},
null
>({
    query:()=>({
        url:END_POINTS.adminNotification,
        method:'DELETE'
    })
}),


deleteAdminNotificationById:builder.mutation<
CommonResponseType & {data:any},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.adminNotification}/${id}`,
        method:'DELETE'
    })
}),


})
})
export const{
 useLazyGetAdminNotificationQuery,
 useDeleteAdminNotificationsMutation,
 useDeleteAdminNotificationByIdMutation
}=userApi;
