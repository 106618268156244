import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
 
 
// import {
//   Challenges,
//   Details,
  
//   PostsAndStories,
// } from "../../../features";
import MainContainer from "../../layout/MainContainer";
import { Details } from "../../features";
import DetailsExercises from "../../features/exercises/detailsExercise";
import { showError } from "../../constants/toast";
import { useLazyGetexercisesByIdQuery } from "../../services/exercise";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageExerciseDetails = () => {
  const[exerciseData,setExerciseData]=useState<any>([]);
  const navigate = useNavigate();
  const{id}=useParams();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const[getDataById]=useLazyGetexercisesByIdQuery();
  const getDataByIdData=async()=>{
    try{
    const response=await getDataById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    setExerciseData(response.data);
    }
    }
    catch(error:any)
    {
    showError(error.data.message);
    }

  } 

  useEffect(()=>{
    if(id)
    getDataByIdData();
  },[])
  return (
    <div>
      
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">View Exercise Details</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-exercises");
              }}
            >
              Back
            </Button>
          </div>
          
          
          {/* <Card className="cards"> */}
            <Box className="custom_tabs">
             
              <CustomTabPanel value={value} index={0}>
                <DetailsExercises exercise={exerciseData}/>
              </CustomTabPanel>
               
            </Box>
          {/* </Card> */}
        </div>
    
    </div>
  );
};

export default ManageExerciseDetails;
