// //import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllworkoutCategoryResponse = {
    workoutCategory: any;
    // workoutCategory: workoutCategoryResponse[];
    count: number;
  };
type workoutCategoryResponse={
    _id:string,
    name:string,
    image:string,
    isBlocked:string
}
type GetTokenParams = {
    // limit?: number;
    page?: number;
    query?: string;
  };

type EncryptedBody = {
  hash: string;
  sek: string;
} | null
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getworkoutCategory:builder.query<
CommonResponseType & {data:GetAllworkoutCategoryResponse},
    GetTokenParams>({
      
        // query:({page,limit,query})=>{
        query:({page,query})=>{
          let url = END_POINTS.workoutCategory;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getworkoutCategoryById:builder.query<CommonResponseType & {data:workoutCategoryResponse},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.workoutCategory}/${id}`,
        method:"GET",
    })
}),
editworkoutCategoryById: builder.mutation<
      CommonResponseType & { data: workoutCategoryResponse },
      { id: string|undefined; body: EncryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.workoutCategory}/${id}`,
        method: "PUT",
        body,
      }),
    }),

addworkoutCategory: builder.mutation<
CommonResponseType & { data: workoutCategoryResponse },
CommonBody
>({
query: (body) => ({
  url: END_POINTS.workoutCategory,
  method: "POST",
  body,
})
}),

deleteworkoutCategoryById:builder.mutation<
CommonResponseType & {data:workoutCategoryResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.workoutCategory}/${id}`,
        method:'DELETE'
    })
}),

})
})

export const{
 useLazyGetworkoutCategoryQuery,
 useLazyGetworkoutCategoryByIdQuery,
 useAddworkoutCategoryMutation,
 useDeleteworkoutCategoryByIdMutation,
 useEditworkoutCategoryByIdMutation,
}=userApi;
