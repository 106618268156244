//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import { UserResponse } from "../types/User";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllUsersResponse = {
    user: UserResponse[];
    count: number;
  };
  
type GetTokenParams = {
    wpagination?:boolean;
    limit?: number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type EditUserById = {
  hash: string;
  sek: string;
} | null
export const userApi=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getUsers:builder.query<
CommonResponseType & {data:GetAllUsersResponse},
    GetTokenParams>({
      
        query:({page,limit,query,wpagination})=>{
          let url = END_POINTS.getUsers;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        if (limit) {
          queryParams.push(`limit=${limit}`);
        }
        if(wpagination){
           queryParams.push(`wpagination=true`)
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),
getUsersById:builder.query<CommonResponseType & {data:UserResponse},
{id:string}>({
    query:({id})=>({
        url:`${END_POINTS.getUsers}/${id}`,
        method:"GET",
    })
}),
changeUserStatus: builder.query<
CommonResponseType & { data: UserResponse },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),
editUserById: builder.mutation<
      CommonResponseType & { data: UserResponse },
      { id: string; body: EditUserById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.getUsers}/${id}`,
        method: "PUT",
        body,
      }),
    }),
deleteUserById:builder.mutation<
CommonResponseType & {data:UserResponse},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.getUsers}/${id}`,
        method:'DELETE'
    })
}),
getUserCSV: builder.query<CommonResponseType & { data: string }, {
    search:string;
  }>({
    query: ({search}) => {
      let url = END_POINTS.exportUsers;
      const queryParams = [];
      if (search) {
        queryParams.push(`search=${search}`);
      }
      if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
      }
     return{ 
      url:url,
      method: "GET",}
    },
  }),

})
})

export const{
    useLazyGetUsersQuery,
    useLazyGetUsersByIdQuery,
    useLazyChangeUserStatusQuery,
    useEditUserByIdMutation,
    useDeleteUserByIdMutation,
    useLazyGetUserCSVQuery,
}=userApi;
