import React from "react";
import MainContainer from "../../layout/MainContainer";
import { DashBoard } from "../../features";
import "./DashBoard.scss";

const DashBoardPage = () => {
  return (
      <DashBoard />
  );
};

export default DashBoardPage;
