import React from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useNavigate } from "react-router-dom";


const WorkoutRoutinesManagement = () => {
    const navigate = useNavigate();
    const rating = 4.7; 
    const renderRatingStars = () => {
        const stars = [];
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating % 1 !== 0;
    
        // Add full stars
        for (let i = 0; i < fullStars; i++) {
          stars.push(<StarIcon key={i} />);
        }
    
        // Add half star if necessary
        if (hasHalfStar) {
          stars.push(<StarOutlineIcon key="half" />);
        }
    
        // Add remaining empty stars to make a total of 5
        const remainingStars = 5 - Math.ceil(rating);
        for (let i = 0; i < remainingStars; i++) {
          stars.push(<StarOutlineIcon key={`empty-${i}`} />);
        }
    
        return stars;
      };
    
  return (

          <div className="main_loyout">
        <div className="dashboard">
        <h3 className="mn_hdng">View Workout Routine Details</h3>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/workout-routines-management");
            }}
          >
            Back
          </Button>
        </div>
        
        
      <Card className="cards">
        <CardContent sx={{ p: 1 }}>
        
          <Grid container spacing={2} className="view_box">
            
           
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Title</Typography>
                    <Typography component="p">Sample Workout Routine 1</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Content</Typography>
                    <Typography component="p">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Format</Typography>
                    <Typography component="p">E-book</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Category</Typography>
                    <Typography component="p">Muscle Building</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Paid Subscriber</Typography>
                    <Typography component="p">
                   20k+
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Added to Cart </Typography>
                    <Typography component="p">
                      6k+
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Active buyers</Typography>
                    <Typography component="p">
                     5k+
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Rating</Typography>
                  {/* Render rating stars */}
                  <Box display="flex">
                    {renderRatingStars()}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12}>
              <Box>
              
              <Typography component="h5" style={{ paddingLeft: '20px' }}>
Routine Document</Typography>
              <figure className="profile_img">
              
                <img src="/static/images/doc.png" alt="" style={{ paddingLeft: '20px' }}/>
              </figure>
             
            </Box>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </div>
 
  );
};

export default WorkoutRoutinesManagement;
