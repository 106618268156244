import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import EditText from "../../components/EditText";
import AddIcon from "@mui/icons-material/Add"; 
import { Cancel, CameraAlt } from "@mui/icons-material";
import { handleDelete } from "../../utils/commonFunctions";
import { errorToast } from "../../helpers";
import { UploadMedia, UploadVideo } from "../../utils/uploadMedia";
import { showError, showToast } from "../../constants/toast";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAddworkoutCategoryMutation, useDeleteworkoutCategoryByIdMutation, useEditworkoutCategoryByIdMutation, useLazyGetworkoutCategoryQuery } from "../../services/workoutCategory";
import { Pagination } from "../../components";
import { workCategoryResponse } from "../../types/workoutCategory";
import Loader from "../../helpers/Loader";
import { useAddworkoutVideoMutation, useEditworkoutVideoByIdMutation, useLazyGetworkoutVideoByIdQuery } from "../../services/workoutVideos";
import WarnModal from "../../components/modals/WarnModal";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddExercises = () => {
 const{id}= useParams();
 const[paid,setPaid]=useState<number>(0);
 const[open,setOpen]=useState(false);
 const[selectedId,setSelectedId]=useState<any>("");
  const[error,setError]=useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState("");
  const[workoutCategoryPicture,setWorkoutCategoryPicture]=useState("");
  const[videoLink,setVideoLink]=useState<any>("");
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>("");
  const[displayEditText,setDisplayEditText]=useState<boolean>(true);
  const[currentVideoDetails,setCurrentVideoDetails]=useState<any>(null);
  const [value, setValue] = useState<number>(0);
  const [category, setCategory] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const[imageId,setImageId]=useState(null);
  const[newCategory,setNewCategory]=useState("");
  const[categories,setCategories]=useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const[duration,setDuration]=useState("");
  const[exerciseName,setExerciseName]=useState("");
  const[getCategory]:any=useLazyGetworkoutCategoryQuery();
  const[addCategory]=useAddworkoutCategoryMutation();
  const[deleteById]=useDeleteworkoutCategoryByIdMutation();
  const[updateCategoryStatus]=useEditworkoutCategoryByIdMutation();
  const[addVideo]:any=useAddworkoutVideoMutation();
  const[updateVideo]=useEditworkoutVideoByIdMutation();
  const[getVideoById]=useLazyGetworkoutVideoByIdQuery();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // Handle form submission
  // };
  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Users"),
    createData("Manage Exercises"),
    createData("Manage Recipes"),
    createData("Workout Routine Mnagement"),
    createData("Revenue Subscription"),
    createData("Workout Videos Management"),
 
    createData("Manage Survey Questionnaries"),
 
    createData(" Notification Management"),
    createData(" Analytics"),
    createData("Manage CMS"),
  ];

  const handleAddRole = (event: React.FormEvent) => {
    event.preventDefault();
    if (newRole.trim() !== "") {
      setRolesData([...rolesData, { name: newRole, enabled: true }]);
      setNewRole("");
    }
  };
  const handleToggle = (index: number) => {
    setRolesData((prevRoles) =>
      prevRoles.map((role, i) =>
        i === index ? { ...role, enabled: !role.enabled } : role
      )
    );
  };

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    throw new Error("Function not implemented.");
  }


  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {

        
        setWorkoutCategoryPicture("");
        setImageId(null);
        return;
      }
      const allowedExtensions = ["png","jpg","jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss",fileExtension);
      if (!allowedExtensions.includes(fileExtension||"")) {
        setLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      const res = await UploadMedia(file);
//      console.log(res,'lklk');
      
      if (res?.statusCode === 200) {
  
        setWorkoutCategoryPicture(res?.data);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setLoading(false);
  };

const getVideoByIdData=async()=>{
try{
setDisplayEditText(false);
const response=await getVideoById({id:id}).unwrap();
if(response.statusCode==200)
{
setCurrentVideoDetails(response?.data);
setDuration(response.data.duration);
setThumbnail(response.data.image);
setDescription(response.data.description);
setVideoLink(response.data.video);
setExerciseName(response.data.exerciseName);
setCategory(response.data.workoutCategory._id)
setPaid(response.data.paid?1:0);
}
}
catch(error:any)
{
showError(error.data.message);
}
finally
{
  setDisplayEditText(true);
}
  } 
  const handleStatusChange=async(index:number)=>{
    try {
      const body = {


        isBlocked:!categories[index]?.isBlocked
      };
      console.log(body);
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await updateCategoryStatus({ id: `${categories[index]._id}` , body:encryptedData}).unwrap();
      showToast(response?.message || "User Updated Successfully");
      setCategories((prevData:any)=>{
        let temp=[...prevData];
        temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
        return temp;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
//      console.log(error);
    }
  }

  const handleAddCategory=async (e:FormEvent)=>{
    e.preventDefault();
    // if(workoutCategoryPicture=="")
    // {
    //   showError("Please add category image")
    //   return;
    // }
    if(newCategory=="")
    {
      showError("Please enter category name")
      return;
    }
    if(newCategory.length>100)
    {
      showError("Category name shouldnt exceed 100 characters");
      return;
    }
    try{
    const body={
      image:workoutCategoryPicture,
      name:newCategory
    }
    
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
    const response=await addCategory(encryptedBody).unwrap();
    if(response.statusCode==200)
    {
    console.log(response.data);
    showToast("Category Added Successfully");
    getCategoryList();
    setWorkoutCategoryPicture("");
    setNewCategory("");
    }
    }
    catch(error:any)
    {
    showError(error.data.message);
    }
    }


    const getCategoryList = async () => {
      try {
        const response = await getCategory({
          page: page,
          // limit: 10,
          // query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (response?.statusCode === 200) {
          setCategories(response?.data?.workoutCategory );
          setTotalCount(response?.data?.count);
        } else {
          setCategories([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    };

    const handleVideoUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const allowedExtensions = ["mp4"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("File extension:", fileExtension);
      if (!allowedExtensions.includes(fileExtension || "")) {
        setLoading(false);
        showError("Invalid file format: only .mp4 files are allowed");
        return;
      }
      setLoading(true);
      console.log("Starting file upload");
    
      const reader = new FileReader();
    
      reader.onloadend = async () => {
        try {
          const dataUrl = reader.result as string;
    
          if (!dataUrl.startsWith("data:") || !dataUrl.includes(",")) {
            throw new Error("Invalid data URL");
          }
    
          // Create a video element
          const video = document.createElement("video");
          video.src = dataUrl;
          video.muted = true;
          video.playsInline = true;
    
          // Wait for the video to load metadata
          await new Promise<void>((resolve, reject) => {
            video.onloadedmetadata = () => {
              resolve();
            };
            video.onerror = (error) => {
              reject(error);
            };
          });
    
          console.log("Video metadata loaded");
    
          // Set the current time to the middle of the video
          video.currentTime = video.duration / 2;
    
          // Wait for the video to seek to the middle frame
          await new Promise<void>((resolve, reject) => {
            video.onseeked = () => {
              resolve();
            };
            video.onerror = (error) => {
              reject(error);
            };
          });
    
          console.log("Video seeked to the middle frame");
    
          // Create a canvas element to capture the frame
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");
    
          if (!context) {
            throw new Error("Failed to get canvas context");
          }
    
          // Draw the frame on the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
          // Get the thumbnail data URL from the canvas
          const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
    
          console.log("Thumbnail captured");
    
          // Convert the thumbnail data URL to Blob
          const thumbnailBlob = await (await fetch(thumbnailDataUrl)).blob();
    
          // Upload the video
          const videoUploadResponse = await UploadVideo(file);
          const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);
    
          if (
            videoUploadResponse?.statusCode === 200 &&
            thumbnailUploadResponse?.statusCode === 200
          ) {
            setVideoLink(videoUploadResponse?.data);
            setThumbnail(thumbnailUploadResponse?.data)
            setLoading(false);
          } else {
            setLoading(false);
            showError("Error uploading video or thumbnail");
          }
        } catch (error) {
          console.error("Error during file upload:", error);
          setLoading(false);
          showError("An error occurred during the upload process");
        }
      };
    
      // Read the file as a data URL
      reader.readAsDataURL(file);
    };
    
    

    console.log(thumbnail);
    
    const captureVideoThumbnail = async (videoBlob: Blob): Promise<string> => {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(videoBlob);
    
        video.addEventListener("loadedmetadata", () => {
          // Ensure that the video's duration is greater than zero
          if (video.duration > 0) {
            // Seek to the middle of the video
            const duration = video.duration;
            const middle = duration / 2;
            video.currentTime = middle;
    
            video.addEventListener("seeked", () => {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const context = canvas.getContext("2d");
              context?.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              const thumbnailDataUrl = canvas.toDataURL("image/jpeg");
    
              video.remove();
              canvas.remove();
    
              resolve(thumbnailDataUrl);
            });
          } else {
            // Handle the case where the video's duration is zero or undefined
            showError("Invalid video file: video duration is zero or undefined");
            video.remove();
            resolve("");
          }
        });
      });
    };

 const handleAddUpdateVideo = async () => {
    const body={
      exerciseName:exerciseName,
      image:thumbnail,
      video:videoLink,
      duration:duration,
      description:description,
      workoutCategory:category,
      paid:paid?true:false
    }
    console.log(body);
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
   try{ 
    const response=(id ? await updateVideo({id:id,body:encryptedBody}).unwrap()  : await addVideo(encryptedBody).unwrap() );
    if(response.statusCode==200)
    {
      showToast(id?"Video details updated succesfully":"Video added successfully");
////        console.log("body",body);
    }}
    catch(error:any)
    {
        showError(error.data.message);
    }

    };
    useEffect(()=>{
      getCategoryList();
      if(id)
      {
        getVideoByIdData();
      }
    },[])
  return (<>
                <Loader isLoad={loading}/> 

        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id?"Edit Workout Video":"Add Workout Videos"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/workout-videos-management");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Workout Category" {...a11yProps(0)} />
                  <Tab label={id?"Edit Workout Video":"Add Workout Videos"} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <form >
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {workoutCategoryPicture ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={workoutCategoryPicture} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setWorkoutCategoryPicture("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  :  "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !workoutCategoryPicture ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Add Category
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="category"
                          variant="outlined"
                          fullWidth
                          placeholder="Add Category"
                          value={newCategory}
                          onChange={(e)=>{
                            if(e.target.value.length<=100)
                            setNewCategory(e.target.value)
                            if(e.target.value.length>100)
                            showError("Category name cant exceed 100 characters")
                          }}
                        />
                      </Grid>
                    </Grid>

                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={handleAddCategory}
                      >
                        Add
                      </Button>
                    </div>
                  </CardContent>
                </form>
                

                <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                        <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Image</TableCell>
                          <TableCell className="mn_hdng">Category</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      {categories?.length ? (
                        categories?.map((item:any, i:any) => (
                          <TableBody>
                            <TableRow>
                              <TableCell className="mn_hdng">{(page - 1) * 10 + i + 1}</TableCell>
                              <TableCell>
                        <figure className="user_img">
                          <img src={item.image?item.image:"/static/images/workoutvideo-placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>
                              <Switch
                                {...label}
                                  size="small"
                                  checked={!item?.isBlocked}
                                  onChange={()=>handleStatusChange(i)}
                              />
                              </TableCell>
                              <TableCell>
                                <IconButton onClick={() => {
                                  setSelectedId(item._id);
                                  setOpen(true);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow

                          // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Workout Category Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
                <Pagination
                module={categories}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
        />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {/* <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className="custom_label">Image</Typography>
                        {image ? (
                          <div className="upload_image_preview">
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />
                            <CancelIcon
                              onClick={() => {
                                setImage("");
                                setFileName("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const files = (
                                    event.target as HTMLInputElement
                                  ).files;
                                  if (
                                    files &&
                                    files[0].type.includes("image")
                                  ) {
                                    setFileName(files[0].name);
                                    setImage(URL.createObjectURL(files[0]));
                                  } else {
                                    setAlertType(0);
                                    setShowAlert(true);
                                    setAlertMessage(
                                      "This field only accepts images."
                                    );
                                  }
                                }}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <img
                                  src="/static/images/user_placeholder.png"
                                  alt=""
                                />
                                <CameraAltIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Full Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          type={"email"}
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Phone Number
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Phone"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Select Role
                        </Typography>

                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleRoleChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="role1">Role 1</MenuItem>
                            <MenuItem value="role2">Role 2</MenuItem>
                            <MenuItem value="role3">Role 3</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">View</TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, i) => (
                                <TableRow>
                                  <TableCell className="mn_hdng">
                                    {row.Module}
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </form> */}
                 {/* <div className="main_layout">
        <div className="dashboard">
          <h1>Add Workout Videos</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/workout-videos-management");
            }}
          >
            Back
          </Button>
        </div> */}
        <div className="main_loyout">
        <div className="dashboard">
      
          {/* <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/workout-videos-management");
            }}
          >
            Back
          </Button> */}
        </div>
        <Card className="cards">
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
              <Grid item lg={6} md={4} sm={4} xs={12} sx={{marginBottom:"30px"}}>
                      <Typography className="custom_label">
                         {"Upload Video"}
                      </Typography>
                      {videoLink ? (
                        <div className="upload_image_preview2">
                          <video controls width="100%" height="100%">
                            <source
                              src={videoLink}
                              type="video/mp4"
                            />
                          </video>
                          <CancelIcon
                            onClick={() =>
                              setVideoLink("")
                            }
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file-vdo">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file-vdo"
                              type="file"
                              inputProps={{
                                accept: "video/*",
                              }}
                              onChange={handleVideoUpload}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}
                      { error && videoLink==""? (
                        <h6 className="err_msg">Workout video is required</h6>
                      ) : (
                        ""
                      )}
                    </Grid>
                    </Grid>
                {/* <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {image && (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon onClick={handleMediaCancel} />
                    </div>
                  )}
                  {!image && (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageChange}
                        />
                        <Button
                          component="span"
                          className="upload_image_btn"
                        >
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid> */}
              <Grid container spacing={2}>
                
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Exercise Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    value={exerciseName}
                    onChange={(e)=>{
                      if(e.target.value.length<=100)
                      setExerciseName(e.target.value)
                      if(e.target.value.length>100)
                      showError("Exercise name can't exceed 100 characters");
                      }}
                    variant="outlined"
                    fullWidth
                    placeholder="Exercise Name"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Time  Duration
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    value={duration}
                    onChange={(e)=>{
                      if(e.target.value.length<=20)
                      setDuration(e.target.value)
                      if(e.target.value.length>20)
                      showError("Duration field can't exceed 20 characters");
                    }}
                    variant="outlined"
                    fullWidth
                    placeholder="Time Duration"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                      Category
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        onChange={handleCategoryChange}
                        displayEmpty
                        // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {categories.map((item:workCategoryResponse,i:number)=>
                        <MenuItem disabled={item.isBlocked} value={item._id}>{item.name}</MenuItem>
                        )
                        }
                       
                         
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ minWidth: 120 }}>
        <Typography className="custom_label">Paid Video</Typography>
      <FormControl fullWidth>
        <Select
          // labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={paid}
          // label="Paid/Free"
          onChange={(e:any)=>setPaid(e.target.value)}
        >
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
              </Grid>
                    {/* Add the description text field below the video upload */}
                    <Grid item xs={12}>
  <Typography className="custom_label">Description</Typography>
  <div style={{ width: '66%' }}> {/* Adjust the width as needed */}
    {displayEditText && <EditText
      content={description}
      setContent={setDescription}
    />}
  </div>
</Grid>

                  </Grid>
              
               
           
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={(e)=>{
                    e.preventDefault();
                    if(videoLink=="")
                    {
                      showError("Video is required");
                      return;
                    }
                    if(exerciseName=="")
                      {
                        showError("Exercise name is required")
                        return;
                      }
                    if(duration=="")
                    {
                      showError("Duration is required");
                      return;
                    }
                    if(category=="")
                    {
                        showError("Category is required");
                        return;
                    }
                    if(exerciseName.length>100)
                    {
                      showError("Exercise name shouldnt exceed 100 characters");
                      return;

                    }
                    if(duration.length>20)
                      {
      showError("Duration field shouldnt exceed 20 characters");
      return;
                        
                      }
                   
                    
                    handleAddUpdateVideo();

                    navigate('/workout-videos-management')
                  }}
                >
                  {id?"Update":"Save"}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
              </CustomTabPanel>
            </Box>
          </Card>
          <WarnModal
        setOpen={setOpen}
        open={open}
        name={"category"|| ""}
        handleDelete={() => handleDelete(deleteById, selectedId, getCategoryList)}
      />
        </div>

    </>
  );
};

 

export default AddExercises;