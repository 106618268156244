import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { NutritionQuestionnaries, WorkoutQuestionnaries } from "../../features";
import { useNavigate } from "react-router-dom";
import { showError, showToast } from "../../constants/toast";
import useAuth from "../../hooks/useAuth";
import { useLazyGetFastingQuery, useEditFastingByIdMutation, useDeleteFastingByIdMutation } from "../../services/fasting";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetSurveyQuery, useEditSurveyByIdMutation, useDeleteSurveyByIdMutation, useAddSurveyMutation } from "../../services/survey";
import { Add, Remove } from "@mui/icons-material";
import { options } from "../../components/LineChart";
import { SurveyResponse } from "../../types/survey";
import { handleDelete } from "../../utils/commonFunctions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Faq = () => {
  const [value, setValue] = useState<number>(0);
   const[newQuestion,setNewQuestion]=useState<any>("");
   const[newOption,setNewOption]=useState<any>("");
   const[newOptionArray,setNewOptionArray]=useState<any>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const user = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [survey, setSurvey] = useState<any>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getSurvey, { isLoading }] = useLazyGetSurveyQuery();
  const [updateSurveyStatus]=useEditSurveyByIdMutation();
  const[deleteById]=useDeleteSurveyByIdMutation();
  const[addNewQuestion]=useAddSurveyMutation();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const addQuestion=async ()=>{
    
    try{
      const body={
      type:value+1,
      question:newQuestion,
      option:newOptionArray
      }
      const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
    const response=await addNewQuestion(encryptedData).unwrap();
    if(response.statusCode==200)
      {
        showToast("Question added successfully");
      }
    }
    catch(error:any)
    {
      showError(error.data.message);
    }
    finally{
      setNewOption("");
    setNewOptionArray([]);
    setNewQuestion("");
      getSurveyList();
    }
  }
  // const checkPermission = () => {
  //   const permission = userData?.permission;
  //   if (permission?.length) {
  //     let idx = -1;
  //     idx = permission?.findIndex(
  //       (ele: Permissions) => ele?.label === "Manage Users"
  //     );
  //     if (idx > -1) {
  //       setHidePermission(permission[idx]);
  //     } else {
  //       navigate(-1);
  //     }
  //   }
  // };

  const getSurveyList = async () => {
    try {
      const response = await getSurvey({
        // page: page,
        // limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log(response.data);
        setSurvey(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setSurvey([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

 
  // const handleStatusChange=async(index:number)=>{
  //   try {
  //     const body = {
  //       isBlocked:!survey[index]?.isBlocked
  //     };
  //     console.log(body);
  //     const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
  //     const response = await updateSurveyStatus({ id: `${survey[index]._id}` , body:encryptedData}).unwrap();
  //     showToast(response?.message || "User Updated Successfully");
  //     setSurvey((prevData:any)=>{
  //       let temp=[...prevData];
  //       temp[index]={...temp[index],isBlocked:!temp[index].isBlocked};
  //       return temp;
  //     });
  //   } catch (error: any) {
  //     showError(error?.data?.message || "");
  //   }
  // }
  useEffect(() => {
//   console.log("TESTING")
    getSurveyList();
  }, [debouncedSearchTerm, page]);
  console.log("FASTING",survey);
  // useEffect(() => {
  //   if (userData && userData?.permission?.length) {
  //     checkPermission();
  //   }
  // }, [userData]);




  const label = { inputProps: { "aria-label": "Switch demo" } };
  const displayQuestions=<Grid container spacing={1}>
  {(user?.role==1)||(user?.permissions?.[6]?.isEdit)?<Grid item xs={12}>
      <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Question</Typography>
              <TextField
                  hiddenLabel
                  type="text"
                  name="name"
                  variant="outlined"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value.trimStart())}
                  placeholder="Question"
              />
              {
                    newOptionArray.map((item:any,id:number)=>{
                      return  <Box  sx={{ display: "flex" }}>
                      <TextField
                          hiddenLabel
                          type="text"
                          name="option"
                          variant="outlined"
                          value={item}
                          disabled
                          placeholder={`Option ${id+1}`}
                          sx={{ mt: 1, width: "65%" }}
                      />
                      
                          <Button
                              variant="contained"
                              onClick={() => {
                                setNewOptionArray((prevData:any)=>{
                                  return prevData.filter((data:any)=>item!=data)

                                })
                              }}
                              style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                          >
                              <Remove/>
                          </Button>
                     
                     
                          {/* <Button
                              variant="contained"
                              onClick={() => {
                                setNewOptionArray((prevData:any)=>[...prevData,newOption])
                                setNewOption("");
                              }}
                              style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                          >
                              <Add />
                          </Button> */}
                     
                  </Box>
                    })
                  }
                  <Box  sx={{ display: "flex" }}>
                      <TextField
                          hiddenLabel
                          type="text"
                          name="option"
                          variant="outlined"
                          value={newOption}
                          onChange={(e) => setNewOption(e.target.value)}
                          placeholder={`Option ${newOptionArray.length+1}`}
                          sx={{ mt: 1, width: "65%" }}
                      />
                      
                          {/* <Button
                              variant="contained"
                              // onClick={() => handleRemoveOption(index)}
                              style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                          >
                              <Remove/>
                          </Button> */}
                     
                     
                          <Button
                              variant="contained"
                              disabled={!newOption}
                              onClick={() => {
                                setNewOptionArray((prevData:any)=>[...prevData,newOption])
                                setNewOption("");
                              }}
                              style={{ marginTop: '0.5rem', marginLeft: '1rem' }}
                          >
                              <Add />
                          </Button>
                     
                  </Box>
                  
          
          </FormControl>
          <Box className="faq_btn">
              <Button
                  variant="contained"
                  disabled={!newQuestion}
                  onClick={addQuestion}
              >
                  Add Question
              </Button>
          </Box>
      </Box>
  </Grid>:null}
  
  
     { survey.map((item:SurveyResponse,index:number)=>{

     return  item.type==value+1 && <Grid  item xs={12}>
          <Box className="faq_box">
              <FormControl sx={{ mb: 2, width: "100%" }}>
                  <Typography className="custom_label">Question</Typography>
                  <TextField
                      hiddenLabel
                      type="text"
                      name="name"
                      variant="outlined"
                      value={item.question}
                      placeholder="Question"
                      disabled
                  />
                 
                     { 
                     item.option.map((item:string,index:number)=> (
                     <>
                       <Grid container spacing={2} sx={{paddingTop:"20px",alignItems:"center"}}>
                          <Typography className="custom_label" style={{marginBottom:"0px"}}sx={{paddingLeft:"20px",paddingRight:"20px"}}>{`Option  ${index+1}`}</Typography>
                          <TextField
                              key={index}
                              hiddenLabel
                              type="text"
                              name="option"
                              variant="outlined"
                              value={item}
                              placeholder={`Option ${index + 1}`}
                              disabled
                              sx={{ mt: 1, width: "65%" }}
                          />  
                       </Grid>
                     </>  
                     ))}
               
              </FormControl>
              <Box className="faq_btn">
                  {(user?.role==1)||(user?.permissions?.[6]?.isDelete)?<Button
                      variant="contained"
                      onClick={() => handleDelete(deleteById, item._id, getSurveyList)}
                  >
                      Remove Question
                  </Button>:null}
              </Box>
          </Box>
      </Grid>
    })  
    }
  
</Grid>



  return (
  
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Survey Questionnaries</h1>
        </div>
        <div>
          <Card className="cards">
            <Box className="custom_tabs">
              {/* <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Nutrition" {...a11yProps(0)} />
                <Tab label="Fitness" {...a11yProps(1)} />
              </Tabs> */}
              <CustomTabPanel value={value} index={0}>
              {displayQuestions}
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={1}>
              {displayQuestions}
              </CustomTabPanel> */}
            </Box>
          </Card>
        </div>
      </div>
  
  );
};

export default Faq;
