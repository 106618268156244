import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
  duration,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import EditText from "../../components/EditText";
import { error } from "console";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/uploadMedia";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAddBiohackingMutation, useEditBiohackingByIdMutation, useLazyGetBiohackingsByIdQuery } from "../../services/biohacking";
import Loader from "../../helpers/Loader";

interface Role {
  name: string;
  enabled: boolean;
}
const AddBioHacking = () => {
  const navigate = useNavigate();
  const{id}=useParams();
  const [role, setRole] = useState("");
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState("");
  const[error,setError]=useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
 const[isLoading,setIsLoading]=useState(false);
  const [value, setValue] = useState<number>(0);
  const[method,setMethod]=useState("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [displayEditText,setDisplayEditText]=useState(true);
  const [category, setCategory] = useState<string>("");
  const [updateData]=useEditBiohackingByIdMutation();
  const [addData]=useAddBiohackingMutation();
  const[getDataById]=useLazyGetBiohackingsByIdQuery();
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const getDataByIdData=async()=>{
    try{
    setDisplayEditText(false);
    const response=await getDataById({id:id}).unwrap();
    if(response.statusCode==200)
    {
    setMethod(response.data.method);
    setDescription(response.data.description);
    setImage(response.data.image);
    }
    }
    catch(error:any)
    {
    showError(error.data.message);
    }
    finally
    {
      setDisplayEditText(true);
    }

  } 
  useEffect(()=>{
    if(id)
    getDataByIdData();
  },[])
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  const handleAddRole = (event: React.FormEvent) => {
    event.preventDefault();
    if (newRole.trim() !== "") {
      setRolesData([...rolesData, { name: newRole, enabled: true }]);
      setNewRole("");
    }
  };
  const handleToggle = (index: number) => {
    setRolesData((prevRoles) =>
      prevRoles.map((role, i) =>
        i === index ? { ...role, enabled: !role.enabled } : role
      )
    );
  };
  const handleAddUpdateBiohacking = async () => {
    const body={
      image:image,
      method:method,
      description:description
    }
    console.log(body);
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
   try{ 
    const response=(id ? await updateData({id:id,body:encryptedBody}).unwrap()  : await addData(encryptedBody).unwrap() );
    if(response.statusCode==200)
    {
      showToast(id?"Biohacking details updated succesfully":"Biohacking added successfully");
////        console.log("body",body);
    }}
    catch(error:any)
    {
        showError(error.data.message);
    }

    };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if(!file)
     {showError("Invalid file")
       return}
       const allowedExtensions = ["png","jpg","jpeg"];
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          console.log("sssssssssssssssssssss",fileExtension);
          if (!allowedExtensions.includes(fileExtension||"")) {
            setIsLoading(false);
            showError("Invalid file format: only png, jpg images are allowed");
            return;
          }
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
    setIsLoading(false);

  };
  return (
    <>
      <Loader isLoad={isLoading}/>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Bio-Hacking</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-bio-hacking");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
             
                <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Upload Image</Typography>
                    {image ? (
                      <div className="upload_image_preview3">
                        <CardMedia component="img" image={image} sx={ {border:"2px dotted black"}} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg,image/jpg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn3">
                            <img
                            style={{border:"1px dotted black"}}
                              src={
                                image
                                  ? image
                                  : "/static/images/placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">Image is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                      
                <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Method
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="category"
                          variant="outlined"
                          fullWidth
                          placeholder="Add Method"
                          value={method}
                          onChange={(e)=>{
                            if(e.target.value.length<=100)
                            setMethod(e.target.value)
                            if(e.target.value.length>100)
                            showError("Method name can't exceed 100 characters")
                          }}
                        />
                      </Grid>
                    </Grid>
                   
                  <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText && <EditText
      content={description}
      setContent={setDescription}
    />}
  </div>
</Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={(e)=>{
                          e.preventDefault();

                          if(image=="")
                            {
                              showError("Image is required")
                              return;
                            }
                          if(method=="")
                            {
                              showError("Method name is required")
                              return;
                            }
                            if(method.length>100)
                              {
                                showError("Method length can't exceed 100 characters")
                                return;
                              }
                          handleAddUpdateBiohacking();
                          navigate('/manage-bio-hacking');
                        }}
                      >
                        {id?"Update":"Add"}
                      </Button>
                    </div>
                  </CardContent>
                </form>
            </Box>
          </Card>
        </div>
    </>
  );
};
export default AddBioHacking;