import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { features } from "process";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetUsersByIdQuery, useEditUserByIdMutation } from "../../services/user";
import { UserResponse } from "../../types/User";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useEditSubscriptionByIdMutation, useLazyGetSubscriptionByIdQuery, useLazyGetSubscriptionQuery } from "../../services/subscription";
import { useAddFeatureMutation, useDeleteFeatureByIdMutation, useLazyGetFeatureQuery } from "../../services/feature";
import { handleDelete } from "../../utils/commonFunctions";
import { CommonBody } from "../../types/General";
import { isNumber, isNumberWithDot } from "../../utils/validations";
import EditText from "../../components/EditText";
const Addsubscription = () => {
  const location = useLocation();
  const { state } = location;
  const [subscription,setSubscription]=useState<any>([]);
  const navigate = useNavigate();
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [addedFeature, setAddedFeature] = useState(""); // State to store the newly added feature
  const [category, setCategory] = useState("");
  const { id } = useParams();
  const[description,setDescription]=useState("");
  const[displayEditText,setDisplayEditText]=useState<boolean>(true);
const[subscriptionId,setSubscriptionId] = useState<string>("");
  const[price,setPrice]=useState<any>("");
  const[feature,setFeature]=useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const[selectedType,setSelectedType]=useState<any>("");
  const[currentFeatureName,setCurrentFeatureName]=useState("");
  const [getSubscriptionById] = useLazyGetSubscriptionByIdQuery();
  const [editSubscription] = useEditSubscriptionByIdMutation();
  const[getFeatures]=useLazyGetFeatureQuery();
  const[deleteFeatureById]=useDeleteFeatureByIdMutation();
  const[addFeature]=useAddFeatureMutation();
  const [getSubscriptions, { isLoading }] = useLazyGetSubscriptionQuery();
  const getSubscriptionsList = async () => {
    try {
      const response = await getSubscriptions({
        page: undefined,
        // limit: 10,
        query: undefined
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubscription(response?.data?.subscription || []);
      } else {
        setSubscription([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleAddSubscription=async()=>{
    if(selectedType=="")
      {
        showError("Select a type");
        return;
      }
      if(price=="")
        {
          showError("Price is required");
          return;
        }
        if(subscriptionId=="")
        {
          showError("Subscription Id is required");
          return ;
        }
    // e.preventDefault();
    const featureToUpdate=[];
    for(let key of feature)
    {
      if(!key.isBlocked)
      {
        featureToUpdate.push({_id:key._id,name:key.name});
      }
    }

    const body = {
      price:price,
      description:description,
      feature_ar:featureToUpdate,
      subscriptionId:subscriptionId
    };
    
    try {
      const encryptedData=generateEncryptedKeyBody(body);
      const response = await editSubscription({ id: selectedType , body:encryptedData}).unwrap();
      showToast( "Subscription Updated Successfully" || response?.message );
      navigate("/manage-subscription");
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  }
  const getFeaturesData=async()=>{
    try{
      const response=await getFeatures({}).unwrap();
      if(response.statusCode==200)
      {
        console.log(response?.data?.feature);
        setFeature(response?.data?.feature);

      }
    }
    catch(error:any)
    {
      showError(error.data.message);
    }
    finally{
      if (id) {
        getSubscriptionDetail(id);
      }
    }
  }
  const getSubscriptionDetail = async (id: string|undefined) => {
    const tempFeatures:any={};
    setDisplayEditText(false);
    try {
      const response = await getSubscriptionById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPrice(response?.data?.price);
        setDescription(response?.data?.description);
        setSubscriptionId(response?.data?.subscriptionId);
        // setFeature(response?.data?.feature);
        setSelectedType(response?.data?._id);
        for(let keys of response?.data?.feature_ar)
        {   console.log(keys._id);
            tempFeatures[keys._id]=true;
        }

        setFeature((prevData:any)=>{
          return prevData.map((item:any)=>{
            return {...item,isBlocked:true}
          })
        })
        setFeature((prevData:any)=>{
        return  prevData.map((item:any)=>{
            if(tempFeatures[item._id])
            {
              return {...item,isBlocked:false}
            }
            else
            {
              return item;
            }
          })
          // const temp=[...prevData];
          // for(let i=0;i<temp.length;i++)
          // {
          //   if(tempFeatures[temp[i]._id])
          //   temp[i]={...temp[i],isBlocked:temp[i].isBlocked};
          // }
          // return temp;
        })

      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
    setDisplayEditText(true);

  };

  // const editSubscriptionData =async (e:any)=>{
  //   e.preventDefault();
  //     const body = {
  //       price:price,
  //       feature:feature
  //     };

  //     try {
  //       const encryptedData=generateEncryptedKeyBody(body);
  //       const response = await editSubscription({ id: `${id}` , body:encryptedData}).unwrap();
  //       showToast(response?.message || "User Updated Successfully");
  //       navigate("/manage-users");
  //     } catch (error: any) {
  //       showError(error?.data?.message || "");
  //       console.log(error);
  //     }
  //   }

const handleAddFeature=async ()=>{
  const data={
    name:currentFeatureName,
    isBlocked:true
  }
  try{
    const encryptedBody=generateEncryptedKeyBody(data) as CommonBody;
    const response=await addFeature(encryptedBody).unwrap();
    if(response.statusCode==200)
    {
      showToast("Feature added successfully")
      getFeaturesData();
      setOpenQueryModal(false);
    }
  }
  catch(error:any)
  {
    showError(error.data.message);
  }
}


  useEffect(() => {
    getFeaturesData();
    getSubscriptionsList();
    // if (id) {
    //   getSubscriptionDetail(id);
    // }
  }, []);


 
  const handleClose = () => {
    setOpenQueryModal(false);
  };

  return (

      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Subscription</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>

        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">Plan Type</Typography>
                  <Select
                    className="select_div "
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty={true}
                    disabled={id?true:false}
                    value={selectedType||""}
                    onChange={(e) => {
                      getSubscriptionDetail(e.target.value);
                      setSelectedType(e.target.value)
                    
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {subscription.map((item:any,i:any)=>
                    <MenuItem value={item._id}>{item.planName}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Price</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  value={price}
                  onChange={(e)=>{
                    
                    if((isNumberWithDot(e.target.value) && parseInt(e.target.value)<=999999 && e.target.value.length<=15)||e.target.value=="")
                    setPrice(e.target.value)
                  if(parseInt(e.target.value)>999999)
                  showError("Price cant exceed $ 999999");
                  if(e.target.value.length>15)
                   showError("Price field cant exceed 15 characters")
                   }
                    
                }
                  name="title"
                  variant="outlined"
                  fullWidth
                  placeholder="Price"
                />
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Subscription Id</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  value={subscriptionId}
                  
                  onChange={(e)=>{
                    setSubscriptionId(e.target.value)
                   
                   }
                    
                }
                  name="subscriptionId"
                  variant="outlined"
                  fullWidth
                  placeholder="Subscription Id"
                />
              </Grid>
              <Grid item xs={12}>
    <Typography className="custom_label">Note</Typography>
    <div style={{ width: '66%' }}> {/* Adjust the width as needed */}
      { displayEditText && <EditText
        content={description}
        setContent={setDescription}
      />}
    </div>
  </Grid>
              <Grid item lg={12} md={4} sm={6} xs={12} display={"flex"} justifyContent={"end"} width={"100%"}>
                <Button
                  className="btn btn_primary"
                  style={{ marginTop: 20 }}
                  onClick={() => {
                    setOpenQueryModal(true); // Open the modal when the button is clicked
                  }}
                >
                  Add Feature
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TableContainer className="table_container" sx={{ margin: 0 }}>
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Feature</TableCell>
                        <TableCell>Selected</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {feature.map((row:any,i:number) => {
                        return <TableRow key={i}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <Checkbox
                              checked={!row.isBlocked}
                              onChange={ 
                                ()=>{
                                  setFeature((prevData:any)=>{
                                   return prevData.map((item:any)=>{
                                      if(item._id==row._id)
                                      {
                                        return {...item,isBlocked:!row.isBlocked}
                                      }
                                      return item;
                                    })
                                  })
                                }
                              }
                              sx={{
                                "& .MuiCheckbox-root": {
                                  color: "black",
                                },
                              }}
                            />
                            <IconButton 
                            onClick={() => handleDelete(deleteFeatureById, row._id, getFeaturesData)}
                            >
            <DeleteIcon />
          </IconButton>
                          </TableCell>
                        </TableRow>
})}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <div className="form_btn">
              <Button size="large" 
              type="submit" 
              className="btn btn_primary"
              onClick={(e)=>{
                e.preventDefault();
                handleAddSubscription();
              }}
              >
                Save
              </Button>
            </div>
          </CardContent>
        </Card>

        <Dialog open={openQueryModal} onClose={handleClose} className="revert_dialog">
          <DialogContent>
            <Typography className="dialog_title">Feature</Typography>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                name="normal"
                type="text"
                value={currentFeatureName}
                placeholder="Feature Name"
                fullWidth
                onChange={(e) => {
                  // if(e.target.value.length<=40)
                  setCurrentFeatureName(e.target.value)
                  // if(e.target.value.length>40)
                    // showError("Feature name cant exceed 40 characters")
                }} // Update addedFeature state
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit" onClick={handleAddFeature}>
                Add
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

      </div>
 
  );
};

export default Addsubscription;
